<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog
      v-if="show"
      ref="dialogRef"
      value="show"
      scrollable
      persistent
      :max-width="professionalId != null ? '1000px' : '800px'"
    >
      <v-card>
        <ModalTitle :title="title" />

        <WaitModal :show="showWaitModal" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <v-form ref="professionalModalForm" lazy-validation>
            <v-row>
              <v-col cols="12" :md="professionalId != null ? 6 : 12">
                <v-text-field
                  :id="getIdByName('firstName')"
                  ref="firstName"
                  v-model="formData.firstName"
                  filled
                  :label="$t('firstName')"
                  :class="formData.hasDci ? '' : 'required-indicator'"
                  :rules="formData.hasDci ? [] : [validationRules.required]"
                  :disabled="formData.hasDci"
                />

                <v-text-field
                  :id="getIdByName('lastName')"
                  ref="lastName"
                  v-model="formData.lastName"
                  :label="$t('lastName')"
                  filled
                  :class="formData.hasDci ? '' : 'required-indicator'"
                  :rules="formData.hasDci ? [] : [validationRules.required]"
                  :disabled="formData.hasDci"
                />

                <v-text-field
                  :id="getIdByName('employeeNumber')"
                  ref="employeeNumber"
                  v-model="formData.employeeNumber"
                  filled
                  :label="$t('employeeNumber')"
                  :class="formData.hasDci ? '' : 'required-indicator'"
                  :rules="formData.hasDci ? [] : [validationRules.required]"
                  :disabled="formData.hasDci"
                />

                <v-text-field
                  :id="getIdByName('phone')"
                  ref="phone"
                  v-model="formData.phone"
                  filled
                  :label="$t('phone')"
                  :class="formData.hasDci ? '' : 'required-indicator'"
                  :rules="formData.hasDci ? [] : [validationRules.required]"
                  :disabled="formData.hasDci"
                />

                <v-text-field
                  :id="getIdByName('email')"
                  ref="email"
                  v-model="formData.email"
                  filled
                  :label="$t('email')"
                  :rules="formData.hasDci ? [] : [validationRules.email]"
                  :disabled="formData.userId != null || formData.hasDci"
                />

                <v-select
                  :id="getIdByName('availableUsers')"
                  ref="availableUsers"
                  v-model="formData.userId"
                  :label="$t('availableUsers')"
                  :items="allUsers"
                  item-text="username"
                  item-value="id"
                  filled
                  clearable
                  :no-data-text="$t('noDataAvailable')"
                  @change="handleUserChange"
                />

                <v-select
                  :id="getIdByName('healthWorkerTypes')"
                  ref="healthWorkerTypes"
                  v-model="formData.typeId"
                  :label="$t('healthWorkerType')"
                  :items="healthWorkerTypes"
                  item-text="name"
                  item-value="id"
                  filled
                  :no-data-text="$t('noDataAvailable')"
                  :class="formData.hasDci ? '' : 'my-2 required-indicator'"
                  :rules="formData.hasDci ? [] : [validationRules.required]"
                  :disabled="formData.hasDci"
                />

                <!-- <v-checkbox ref="checkActive" v-model="formData.active" :label="$t('active')" /> -->
              </v-col>
              <v-col v-if="professionalId !== null" :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'">
                <detail-field
                  :id="getIdByName('createdDate')"
                  ref="createdDate"
                  v-model="formData.createdDate"
                  :label="$t('createdDate')"
                />

                <detail-field
                  :id="getIdByName('createdBy')"
                  ref="createdBy"
                  v-model="formData.createdBy"
                  :label="$t('createdBy')"
                />

                <detail-field
                  :id="getIdByName('modifiedDate')"
                  ref="modifiedDate"
                  v-model="formData.modifiedDate"
                  :label="$t('modifiedDate')"
                />

                <detail-field
                  :id="getIdByName('modifiedBy')"
                  ref="modifiedBy"
                  v-model="formData.modifiedBy"
                  :label="$t('modifiedBy')"
                />

                <detail-field
                  :id="getIdByName('partnerCode')"
                  ref="partnerCode"
                  :value="$t(formData.partnerCode)"
                  :label="$t('dataSource')"
                />
              </v-col>
            </v-row>
          </v-form>

          <RequiredFieldsLegend v-if="!formData.hasDci" />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <SaveButton
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="createOrUpdateAction"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import healthcareProfessionalService from '@/services/healthcareProfessionalService';
import validationRulesMixin from '@/validationRulesMixin';
import SaveButton from '@/components/SaveButton.vue';

export default {
  name: 'HealthCareProfessionalModal',

  components: { SaveButton },

  mixins: [translation, accessibility, validationRulesMixin],

  props: {
    professionalId: {
      type: Number,
      required: false,
      default: null,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      title: '',
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,
      formData: {
        firstName: '',
        lastName: '',
        employeeNumber: '',
        typeId: null,
        active: true,
        email: '',
        phone: '',
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
        userId: null,
      },
      originalFormData: {},
      healthWorkerTypes: [],
      availableUsers: [],
      allUsers: [],
    };
  },
  watch: {
    show: function () {
      if (this.show) {
        if (this.professionalId != null) {
          this.editClicked();
        } else {
          this.init();
        }
      }
    },
  },

  created: function () {},

  methods: {
    async init() {
      this.title = this.$t('createProfessional');
      this.formData = {
        firstName: '',
        lastName: '',
        employeeNumber: '',
        typeId: null,
        active: true,
        email: '',
        userId: null,
        phone: '',
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
        hasDci: false,
        partnerCode: '',
      };
      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      await this.loadUsers();
      this.allUsers = this.availableUsers;
      await this.getHealthWorkerTypesAction();
    },

    async getHealthWorkerTypesAction() {
      this.isLoading = true;

      try {
        this.healthWorkerTypes = await healthcareProfessionalService.getHealthcareProfessionalTypes();
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
      this.isLoading = false;
    },

    async loadUsers() {
      this.isLoading = true;

      try {
        this.availableUsers = await healthcareProfessionalService.getAvailableUsers();
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
      this.isLoading = false;
    },

    async editClicked() {
      this.title = this.$t('editProfessional');
      try {
        let [professional] = await Promise.all([
          healthcareProfessionalService.getHealthcareProfessionalById(this.professionalId),
          this.loadUsers(),
          this.getHealthWorkerTypesAction(),
        ]);

        this.formData.hasDci = professional.hasDci;
        this.formData.partnerCode = professional.partnerCode;
        this.formData.firstName = professional.firstName;
        this.formData.lastName = professional.lastName;
        this.formData.employeeNumber = professional.employeeNumber;
        this.formData.typeId = professional.healthworkerType?.id;
        this.formData.active = professional.active;
        this.formData.email = professional.email;
        this.formData.phone = professional.phone;
        this.formData.createdDate = professional.createdAt;
        this.formData.createdBy = professional.createdBy != null ? professional.createdBy.username : '';
        this.formData.modifiedDate = professional.updatedAt;
        this.formData.modifiedBy = professional.updatedBy != null ? professional.updatedBy.username : '';
        this.formData.userId = professional.virtuoseUser != null ? professional.virtuoseUser.id : null;
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));

        let associatedProfessionalUser = professional.virtuoseUser;
        this.allUsers =
          associatedProfessionalUser !== null
            ? [associatedProfessionalUser, ...this.availableUsers]
            : this.availableUsers;
      } catch (error) {
        this.error = error;
      }
    },

    async createOrUpdateAction() {
      var isValid = this.$refs.professionalModalForm.validate();

      if (!isValid || this.isProcessing) return;
      var data = {
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        employeeNumber: this.formData.employeeNumber,
        healthworkerTypeId: this.formData.typeId,
        phone: this.formData.phone,
        email: this.formData.email.toLowerCase(),
        active: this.formData.active,
        userId: this.formData.userId,
      };

      this.showWaitModal = true;
      this.isProcessing = true;

      try {
        if (this.professionalId == null) {
          await healthcareProfessionalService.createHealthcareProfessional(data);
        } else {
          await healthcareProfessionalService.updateHealthcareProfessional(this.professionalId, data);
        }

        this.closeDialog();
        this.$emit('refresh');
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
      this.isProcessing = false;
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
    handleUserChange() {
      if (!this.formData.hasDci) {
        this.setEmailFromAvailableUsers();
      }
    },
    setEmailFromAvailableUsers() {
      if (this.formData.userId !== null) {
        const selectedUser = this.allUsers.find((user) => user.id === this.formData.userId);
        this.formData.email = selectedUser.username;
      } else {
        if (this.originalFormData.userId) {
          this.formData.email = '';
        } else {
          this.formData.email = this.originalFormData.email;
        }
      }
    },
  },
};
</script>

<style scoped></style>
